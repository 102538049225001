import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

//登录验证
router.beforeEach((to, from, next) => {
  console,console.log("进来了",to);
      console,console.log("进来了",from);
      console,console.log("进来了",next);
  if (to.meta.login) {
    //需要登录的验证一下
    const loginStatus = sessionStorage.getItem('login')
    if (!loginStatus) {
      //未登录
      next({
        path: '/login',
      })
    } else {
      //登录成功
      next()
    }
  } else {
    //不需要登录的直接进
    next()
  }
})

export default router
